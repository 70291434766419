import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import PrimaryBox from 'components/Advanced_User/OpenHAB_Home_Automation/PrimaryBox';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "OpenHAB 2 Home Automation",
  "path": "/Advanced_User/OpenHAB_Home_Automation/",
  "dateChanged": "2018-09-28",
  "author": "Mike Polinowski",
  "excerpt": "The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_OpenHAB.png",
  "social": "/images/Search/AU_SearchThumb_OpenHAB.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_OpenHAB2_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <SEOHelmet title='OpenHAB 2 Home Automation' dateChanged='2018-09-28' author='Mike Polinowski' tag='INSTAR IP Camera' description='The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_OpenHAB.png' twitter='/images/Search/AU_SearchThumb_OpenHAB.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/OpenHAB_Home_Automation/' locationFR='/fr/Advanced_User/OpenHAB_Home_Automation/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "openhab-2-home-automation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#openhab-2-home-automation",
        "aria-label": "openhab 2 home automation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB 2 Home Automation`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#camera-api-to-node-red-to-openhab"
        }}>{`Camera API to Node-RED to OpenHAB`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#openhab-installation-on-windows"
        }}>{`OpenHAB Installation on Windows`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#openhab2-configuration"
        }}>{`OpenHAB2 Configuration`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#connect-the-mqtt-server-to-openhab2"
            }}>{`Connect the MQTT Server to openHab2`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#add-items"
            }}>{`Add Items`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#access-your-smarthome-remotely"
        }}>{`Access your SmartHome Remotely`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#openhab--node-red-tutorials"
        }}>{`OpenHAB & Node-RED Tutorials`}</a></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "camera-api-to-node-red-to-openhab",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#camera-api-to-node-red-to-openhab",
        "aria-label": "camera api to node red to openhab permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera API to Node-RED to OpenHAB`}</h2>
    <p>{`In our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/"
      }}>{`previous tutorial`}</a>{` we already mapped our INSTAR REST API to MQTT with Node-RED. That means, that we are now able to publish a message via the MQTT protocol and trigger Node-RED to send a `}<a parentName="p" {...{
        "href": "/en/Advanced_User/CGI_Commands/"
      }}>{`CGI Command`}</a>{` to our camera. We will later use `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/Mosquitto_Installation_on_Windows"
      }}>{`MQTT.fx`}</a>{` to send those messages or build a `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/Advanced/"
      }}>{`Dashboard in Node-RED`}</a>{` to publish messages with a press of a button.`}</p>
    <p>{`But the MQTT protocol is an IoT (Internet of Things) standard that makes you camera compatible to a wide range of IoT hardware and software home automation systems. Let us take a look how we can level this new power to integrate our IP camera into an existing smart home. If you are not familiar with the `}<a parentName="p" {...{
        "href": "https://www.openhab.org/"
      }}>{`openHAB 2`}</a>{` system, follow the instructions below to set it up on your Windows PC. For advanced users, you might want to skip ahead to the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/IP_Camera_Control/"
      }}>{`Instruction part`}</a>{` or straight to the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/Projects/"
      }}>{`Node-RED & openHAB Projects`}</a>{` page.`}</p>
    <h2 {...{
      "id": "openhab-installation-on-windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#openhab-installation-on-windows",
        "aria-label": "openhab installation on windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB Installation on Windows`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/OpenHAB_Home_Automation/OpenHAB_Installation_on_Windows/"
        }}>{`OpenHAB Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/de/Advanced_User/OpenHAB_Home_Automation/Node-RED_Installation_on_Windows/"
        }}>{`Node-RED Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/OpenHAB_Home_Automation/Mosquitto_Installation_on_Windows/"
        }}>{`Mosquitto / MQTT Installation`}</a></li>
    </ul>
    <h2 {...{
      "id": "openhab2-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#openhab2-configuration",
        "aria-label": "openhab2 configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB2 Configuration`}</h2>
    <p>{`The configuration files for open hub can be found in the `}<strong parentName="p">{`./conf`}</strong>{` directory of the installation dir. First we want to configure the MQTT service to connect to our MQTT Server.`}</p>
    <h3 {...{
      "id": "connect-the-mqtt-server-to-openhab2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#connect-the-mqtt-server-to-openhab2",
        "aria-label": "connect the mqtt server to openhab2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connect the MQTT Server to openHab2`}</h3>
    <p>{`Go back to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:8080`}</code>{` and select the PaperUI, go to Add-ons and search for MQTT-bindings inside the `}<strong parentName="p">{`Bindings`}</strong>{` tab - in my case this was already installed, if not, hit install:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d322f8d07bca6716a75acb6560d6f0e3/e9beb/Mosquitto_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.304347826086953%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA/UlEQVQY033GzUrDQBSG4bmlutU7UWuzEPytK72DIoimQYy9BjcW6cJdu7DEYEFxZWIpVCxVqsZkkkzOmZnkiF0bXx4+PtYfD+8nL87I77ldZ9C7dK6tftt2O/ZtqZbbOb25Ou62mUoTnL2LOKXWDtUrtLtA2/PdK1ev0MES7S+yKI5RSk2kLhraXFGHa3i0Do1ltAx5sgpnG2hWsVmD8y1s1tCsgr35eywDLYOlQQCcE2SxzD+QIiSRF0j0D8gJoFCo2fhu8OY9c//JG34+TkCEXyBEoVShJGn1h1wXSZz4nppOGXAuk5RQjGbq4RVTHnyHYTQPEamkLBNa6x8UjCaFRMBEwwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d322f8d07bca6716a75acb6560d6f0e3/e4706/Mosquitto_11.avif 230w", "/en/static/d322f8d07bca6716a75acb6560d6f0e3/d1af7/Mosquitto_11.avif 460w", "/en/static/d322f8d07bca6716a75acb6560d6f0e3/b6582/Mosquitto_11.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d322f8d07bca6716a75acb6560d6f0e3/a0b58/Mosquitto_11.webp 230w", "/en/static/d322f8d07bca6716a75acb6560d6f0e3/bc10c/Mosquitto_11.webp 460w", "/en/static/d322f8d07bca6716a75acb6560d6f0e3/87ca7/Mosquitto_11.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d322f8d07bca6716a75acb6560d6f0e3/81c8e/Mosquitto_11.png 230w", "/en/static/d322f8d07bca6716a75acb6560d6f0e3/08a84/Mosquitto_11.png 460w", "/en/static/d322f8d07bca6716a75acb6560d6f0e3/e9beb/Mosquitto_11.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d322f8d07bca6716a75acb6560d6f0e3/e9beb/Mosquitto_11.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now go to the openHAB2 install dir - if you followed this guide, this will be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`C:\\openhab230\\conf\\services`}</code>{` and open the `}<strong parentName="p">{`mqtt.cfg`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/faf5fc46dedd21288a7cd0e5821c8e55/e9beb/Mosquitto_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACSElEQVQoz22SS0/UUBiG5wcQ8A8YM1ymnbanpx3auQEGEyQRIiAi6MK4cOXKvQtidMZ4Wfg3jEsXxj1BERWFCESNogZhAMPAKDAZsI85rYgxNHnyfuckfc7lOzHdbceSHi26xPA6ae88RSp9nGZdEm8xaWyxiB9CY+JwYrrlYcksIpXHcrIIN49wc1hOLso/telkMWU2rMOxzGDITJjKsU9Mt3wSIo1mp9H+y4TwSdppkkLhR2lHtWa2/pWoep9Y0vI4YfmcFGm6hE+XnY5QtfCRSuBkScoMSSeDrsZqd04Ww43mNeGhqUWET8ywPLpNj9MiTY/l0Wv59KoUPj3JVuxmBy3hIvUUtpZCainshBsiEy6iWR7QIonpwkeXWcxUG4abw0zlMRQyj9ndhbg8jHlxiCND/TSc7aNh5Az1w/3Unx+k/sIgdSMD1A0PRHmuj5i6F8NOhxcepp0JG2DoDsalK7Q9GuP+/ASF98+5/m6SG5+mKCy8pjj/jMLbcYpzTynOKsa59fGVEvphp0SqLeyo6rb0OmjSJFevFVmrwO72LlTXCTYrsLUD39dhaoadiRcwPQszc+xNTsHnxX1h9kDo5nH8DuIJyc0791BfuVxmu1plLwjYI6D8c4vy6hq1SoXaxga7m5sR1eo/R1bHtKN3paTHmixGi3ch+EFpuUStthvKCQIqi99YeDPN2pevBGoK+BUS7Asz4e6UzAwfd46j8SSjhdssVPZYWiqFIvUj2zssj43z8sFDPjx+wlKpxOrKSsTqKr8BgmXaFuI6swMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/faf5fc46dedd21288a7cd0e5821c8e55/e4706/Mosquitto_12.avif 230w", "/en/static/faf5fc46dedd21288a7cd0e5821c8e55/d1af7/Mosquitto_12.avif 460w", "/en/static/faf5fc46dedd21288a7cd0e5821c8e55/b6582/Mosquitto_12.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/faf5fc46dedd21288a7cd0e5821c8e55/a0b58/Mosquitto_12.webp 230w", "/en/static/faf5fc46dedd21288a7cd0e5821c8e55/bc10c/Mosquitto_12.webp 460w", "/en/static/faf5fc46dedd21288a7cd0e5821c8e55/87ca7/Mosquitto_12.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/faf5fc46dedd21288a7cd0e5821c8e55/81c8e/Mosquitto_12.png 230w", "/en/static/faf5fc46dedd21288a7cd0e5821c8e55/08a84/Mosquitto_12.png 460w", "/en/static/faf5fc46dedd21288a7cd0e5821c8e55/e9beb/Mosquitto_12.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/faf5fc46dedd21288a7cd0e5821c8e55/e9beb/Mosquitto_12.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add the MQTT URL as follows: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mosquitto.url=tcp://localhost:1883`}</code>{` (The broker name - in our case we choose `}<em parentName="p">{`mosquitto`}</em>{` - can be chosen freely). Make sure that you set all MQTT Server variables according to your MQTT Server configuration in Node-RED - e.g.:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`mosquitto.url=tcp://localhost:1883
mosquitto.clientId=openhab2
mosquitto.user=mosquitto
mosquitto.pwd=asolidpassword
mosquitto.qos=1
mosquitto.retain=false
mosquitto.async=true`}</code></pre></div>
    <p>{`You can double-click a MQTT-Node in your Node-RED flows to check and edit your configuration:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5ce067b0a51f324bec49254d3c4f047e/e9beb/nodeRED_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.260869565217384%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABWklEQVQY03XNW0/CMACG4f3//6AxRBEPsGk4ZFhGyigrY7SDC2Iiu3DoDVrj2IG0azeFeCAxPt/1m09zMfYwHtmDMXa8iYcd7I5382d+EAQPy+UqDOMDmz0Wrl5XT5pXrcGTs9FRhXYtc9C7uqk1WnULWsEyiOP4fS+Kos23KIriLFscn95f1LWpaSLdsM8rw2YVORDAXr11bbR1MiNvjIWP4fPTc5zE2x9CZOt10mpnea7R+YzO5w7u980qgl0I+0bz+tKoTShOk0SIXEnJGMuyrCgKpZQqCrHd8vWLVEojhFBCKJ2NkW13GnZHd0B7hAb3CzeKNkIIzjljjHMuD6iylFLuY0p93yfTKUIWALrVu7V6TX/up2ma57lS6itQqjywiymlhJDhcAgAuANdhGDTNC6NKvacNEm5EFJKzrn64/cZY2zt9DEeAwj0lj4hblmWRVGU//iMPwAKQqAgTCmPbQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ce067b0a51f324bec49254d3c4f047e/e4706/nodeRED_05.avif 230w", "/en/static/5ce067b0a51f324bec49254d3c4f047e/d1af7/nodeRED_05.avif 460w", "/en/static/5ce067b0a51f324bec49254d3c4f047e/b6582/nodeRED_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5ce067b0a51f324bec49254d3c4f047e/a0b58/nodeRED_05.webp 230w", "/en/static/5ce067b0a51f324bec49254d3c4f047e/bc10c/nodeRED_05.webp 460w", "/en/static/5ce067b0a51f324bec49254d3c4f047e/87ca7/nodeRED_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ce067b0a51f324bec49254d3c4f047e/81c8e/nodeRED_05.png 230w", "/en/static/5ce067b0a51f324bec49254d3c4f047e/08a84/nodeRED_05.png 460w", "/en/static/5ce067b0a51f324bec49254d3c4f047e/e9beb/nodeRED_05.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5ce067b0a51f324bec49254d3c4f047e/e9beb/nodeRED_05.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3dd8377f74eeb6416e2c03b86d34a8d2/e9beb/nodeRED_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABTUlEQVQY02XQW0+DMBTA8X3/7+GL0/lgyNxF2JXbCGVvRpM5NiVCESkttIWWGWAzUX/JefznnJyerusb27YsU1+vNxvbMs3Dwf+M4zCKdrvX/X7v+z6EMcY46yCUERI+PQdg2wMAzEeTyd39cqpZtjNQbg5vvhCSUlqWJeecl5wxVvzIcybFcay+XF03sWUZq5k6H/WXj33DNMIoykle1zVjTEophJC/CSHqVs91Xc/zXAD01XQxvp1PhxBGaZpGMKqEkELEECbJF8aYEBLHcRiGhOQkyxilzWYAgNfM1lyoi4fBR3DM81xKiRDinHerhBCc8+7w0+lU17WU8hy7ALiua9m2NhuG4TshBLWSJCnLsv6n65vY8zzHcTRNUxRFU1UIoyzDVVWxVlEUlNI/2Tm2LwzDmLWCIEjTFEKIEKKUJkkCY4gxPj//gjH2DWJqr7WZzuGwAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3dd8377f74eeb6416e2c03b86d34a8d2/e4706/nodeRED_06.avif 230w", "/en/static/3dd8377f74eeb6416e2c03b86d34a8d2/d1af7/nodeRED_06.avif 460w", "/en/static/3dd8377f74eeb6416e2c03b86d34a8d2/b6582/nodeRED_06.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3dd8377f74eeb6416e2c03b86d34a8d2/a0b58/nodeRED_06.webp 230w", "/en/static/3dd8377f74eeb6416e2c03b86d34a8d2/bc10c/nodeRED_06.webp 460w", "/en/static/3dd8377f74eeb6416e2c03b86d34a8d2/87ca7/nodeRED_06.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3dd8377f74eeb6416e2c03b86d34a8d2/81c8e/nodeRED_06.png 230w", "/en/static/3dd8377f74eeb6416e2c03b86d34a8d2/08a84/nodeRED_06.png 460w", "/en/static/3dd8377f74eeb6416e2c03b86d34a8d2/e9beb/nodeRED_06.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3dd8377f74eeb6416e2c03b86d34a8d2/e9beb/nodeRED_06.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bbce041e36317a70f55fb9cc6a38561b/e9beb/nodeRED_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABN0lEQVQY033OTXOCMBCAYf//D+lFp+1BplZRhFpMaEnSXjrtwVG0KhERTULAEOiI049Dx+e0e3hnt+G67pMHIQRj99HzPAhAEARRFNEwnEwm0+lsFsyiKGKMHc72+wPn4dv7krw2MEZ2t9e7bY9MG3rP10Zr/jkviiLLsuPxmNeklOkPITKtg475cdVsIIQgAKOhNew2nX4TQEAp5VxordM0VUoV/ylrp5gQgjF2H0yn17LNzobSaLuN45gzLrjY1nNVllrrOI4p3Qgu2IFlUp5ijBHGBJEX4PSd+5v1cpEk+zzPd7tdKlKtdVEUuqa+nddzjDFGyPchhEP7brVaMMaVUlyIPM/L+uZfv29jjAkhvu9blmUYxmAwSJKkqqqyLKuLTjEAANZcd2zV1utQKSWlzC6SUn4BviqxFcQ1EK0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bbce041e36317a70f55fb9cc6a38561b/e4706/nodeRED_07.avif 230w", "/en/static/bbce041e36317a70f55fb9cc6a38561b/d1af7/nodeRED_07.avif 460w", "/en/static/bbce041e36317a70f55fb9cc6a38561b/b6582/nodeRED_07.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bbce041e36317a70f55fb9cc6a38561b/a0b58/nodeRED_07.webp 230w", "/en/static/bbce041e36317a70f55fb9cc6a38561b/bc10c/nodeRED_07.webp 460w", "/en/static/bbce041e36317a70f55fb9cc6a38561b/87ca7/nodeRED_07.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bbce041e36317a70f55fb9cc6a38561b/81c8e/nodeRED_07.png 230w", "/en/static/bbce041e36317a70f55fb9cc6a38561b/08a84/nodeRED_07.png 460w", "/en/static/bbce041e36317a70f55fb9cc6a38561b/e9beb/nodeRED_07.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bbce041e36317a70f55fb9cc6a38561b/e9beb/nodeRED_07.png",
              "alt": "Mosquitto MQTT",
              "title": "Mosquitto MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "add-items",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-items",
        "aria-label": "add items permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add Items`}</h3>
    <p>{`Go to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`C:\\openhab230\\conf\\services`}</code>{` to create a configuration file named `}<strong parentName="p">{`office.things`}</strong>{` and add the following declaration `}<em parentName="p">{`//This is the Things file`}</em>{`. Eventually we will populate this file with devices that require a thing declaration, keep in mind that not all devices need it.`}</p>
    <p>{`To be able to access things - such as a light switch - we need to add them to an items file `}<strong parentName="p">{`office.items`}</strong>{` in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`C:\\openhab230\\conf\\items`}</code>{`. For demo purposes we can add a `}<strong parentName="p">{`Switch Item`}</strong>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`// This is the Items File

//Demo items
Switch MyFirstSwitch "Demo Switch"`}</code></pre></div>
    <p>{`In this case `}<strong parentName="p">{`Switch`}</strong>{` will be the type of item we add, `}<strong parentName="p">{`MyFirstSwitch`}</strong>{` is its name and `}<strong parentName="p">{`Demo Switch`}</strong>{` will be used as it's label.`}</p>
    <p>{`Now we have to add the switch to our user interface, by adding a `}<strong parentName="p">{`office.sitemap`}</strong>{` file in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`C:\\openhab230\\conf\\sitemap`}</code>{`. The sitemap file is how you interact with the devices or the user interface:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`sitemap home label="INSTAR - SmartOffice"
{
       Frame label="Demo"
       {
               Switch item=MyFirstSwitch
       }
}`}</code></pre></div>
    <p>{`To activate the sitemap, go to `}<strong parentName="p">{`Configuration`}</strong>{` and `}<strong parentName="p">{`Services`}</strong>{`, choose `}<strong parentName="p">{`UI`}</strong>{` and select to configure the `}<strong parentName="p">{`Basic UI`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c301fcafdcd160bf2c63b0163a45d259/e9beb/openhab2_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABFklEQVQY042QS0oDQRCG5yI+lrrwGjJMCKLgAYyrgLuALkQTlbjxDB7By4gHUDAgmZnM2NPTXc9uGUMwuvKjNv9XUBR/0n7MPjmGx5GcbOjZng53dLgbBpthsPUzp9u/4sokAj7EqE8PerUv0yOZZHzTl+tUJplMD2WcyaRH98d82+fzVC56OEr5MqW7AxlnSV7kpmmsMXVZoLOKoMoxRt/aRZ6Td51hUiZbV4v5nL1XwiAkCElZlsYY55wHBCRW5aqyr2/QNA46IarM7AEAsfVeVGOMgARISfH8YprGOYeIzKwhcFXj+wytZRH6BgC890QkK+N9dy2Btl2ui6Kw1sYYuwpiJCJc409ckizf4DUQwBiD/+AL2MRp1R5aWLkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c301fcafdcd160bf2c63b0163a45d259/e4706/openhab2_06.avif 230w", "/en/static/c301fcafdcd160bf2c63b0163a45d259/d1af7/openhab2_06.avif 460w", "/en/static/c301fcafdcd160bf2c63b0163a45d259/b6582/openhab2_06.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c301fcafdcd160bf2c63b0163a45d259/a0b58/openhab2_06.webp 230w", "/en/static/c301fcafdcd160bf2c63b0163a45d259/bc10c/openhab2_06.webp 460w", "/en/static/c301fcafdcd160bf2c63b0163a45d259/87ca7/openhab2_06.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c301fcafdcd160bf2c63b0163a45d259/81c8e/openhab2_06.png 230w", "/en/static/c301fcafdcd160bf2c63b0163a45d259/08a84/openhab2_06.png 460w", "/en/static/c301fcafdcd160bf2c63b0163a45d259/e9beb/openhab2_06.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c301fcafdcd160bf2c63b0163a45d259/e9beb/openhab2_06.png",
              "alt": "openHAB2",
              "title": "openHAB2",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Type in the name of your sitemap - we used `}<strong parentName="p">{`office`}</strong>{` - and click `}<strong parentName="p">{`Save`}</strong>{`. You can then repeat this step with the `}<strong parentName="p">{`Classic UI`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0384e5166724e096f2510cb5d3f147ed/e9beb/openhab2_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABpklEQVQoz3WS3W7aQBCFeX2EaMlLRFUq9ZI7F4FEWoHkKuIiUrGBgtE2Eq1Z76z3f6eyp3Ggab6r1axmztkz20vTlDG22+2Kojgej/v9viiKQwtj7OcrGGOn02kymYxGo16WZQBA1bIs6cBblFJVVXHOAaCqKtFyPp9DCGmaDofDXp7nMUbvfbwAW5xzAGCMiTGGEOjKe4+Ii8Wi3+83ylprY4xzrmsLLd57zrm11j3jvVdKIeJyuRwMBo0yImqtrbXdeJpCytZaY4x95qp5s9mEELTW+IoQQi2lqusQwmXxpTnLMiklADjnOodKKe+9D+EXP+tWsBMnmRfbpGwvaEJC9LUqdz/44VCVpRCiG3FlO8YIABTjX9o323Umi6JeZ/HpqSte2c7znGzTYIq9sRyj/r5mq8dy9Si2B1AOhBJCCbgOjNKmNmst7bwRcV7+5lBWsjZQG5BaSg1S/7sqMnP5T2jV/11B80moebvdIiLFi28TY9TGYET7sMKH1bfPk8G7YW88Hs9msyRJptPp17eZz+dJkny5v5/efZx9uPt0e/v+5uYP/QQDyuC5CfEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0384e5166724e096f2510cb5d3f147ed/e4706/openhab2_07.avif 230w", "/en/static/0384e5166724e096f2510cb5d3f147ed/d1af7/openhab2_07.avif 460w", "/en/static/0384e5166724e096f2510cb5d3f147ed/b6582/openhab2_07.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0384e5166724e096f2510cb5d3f147ed/a0b58/openhab2_07.webp 230w", "/en/static/0384e5166724e096f2510cb5d3f147ed/bc10c/openhab2_07.webp 460w", "/en/static/0384e5166724e096f2510cb5d3f147ed/87ca7/openhab2_07.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0384e5166724e096f2510cb5d3f147ed/81c8e/openhab2_07.png 230w", "/en/static/0384e5166724e096f2510cb5d3f147ed/08a84/openhab2_07.png 460w", "/en/static/0384e5166724e096f2510cb5d3f147ed/e9beb/openhab2_07.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0384e5166724e096f2510cb5d3f147ed/e9beb/openhab2_07.png",
              "alt": "openHAB2",
              "title": "openHAB2",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now open the `}<strong parentName="p">{`Basic UI`}</strong>{` in a new tab `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:8080/basicui/app`}</code>{` and see the switch we just created:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e0db97357f3989701993141322fec8c1/e9beb/openhab2_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.478260869565215%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAlUlEQVQY02NYf+fK+vvXll09M+/kwVU3zs+/eirz7O6YMztiiUAMNaVlVcUl1UUlVcXF5fmFZfkFhXn5+Xl5xCAGAUFBASFBYVFRMQkJPn5+PgEUwM/PL4AbMCgqKMjJy+tpaVuZmurq6eqgAmNjYx0cQFtbm0FOTk5WTk5NXkFbRVVTS0tFGQFUVFQMDAyUcQAlJSUAKQNY4gXuNhYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e0db97357f3989701993141322fec8c1/e4706/openhab2_08.avif 230w", "/en/static/e0db97357f3989701993141322fec8c1/d1af7/openhab2_08.avif 460w", "/en/static/e0db97357f3989701993141322fec8c1/b6582/openhab2_08.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e0db97357f3989701993141322fec8c1/a0b58/openhab2_08.webp 230w", "/en/static/e0db97357f3989701993141322fec8c1/bc10c/openhab2_08.webp 460w", "/en/static/e0db97357f3989701993141322fec8c1/87ca7/openhab2_08.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e0db97357f3989701993141322fec8c1/81c8e/openhab2_08.png 230w", "/en/static/e0db97357f3989701993141322fec8c1/08a84/openhab2_08.png 460w", "/en/static/e0db97357f3989701993141322fec8c1/e9beb/openhab2_08.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e0db97357f3989701993141322fec8c1/e9beb/openhab2_08.png",
              "alt": "openHAB2",
              "title": "openHAB2",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To add functionality to our switch, we need to add a `}<strong parentName="p">{`office.rules`}</strong>{` file in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`C:\\openhab230\\conf\\rules`}</code>{`. This is the file that does all the automation.`}</p>
    <h2 {...{
      "id": "access-your-smarthome-remotely",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#access-your-smarthome-remotely",
        "aria-label": "access your smarthome remotely permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Access your SmartHome Remotely`}</h2>
    <p>{`You can use the `}<a parentName="p" {...{
        "href": "https://myopenhab.org/users"
      }}>{`OpenHAB Cloud`}</a>{` to access your Smarthome over the internet.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/930601a780f6ba270d14157ed112787e/e9beb/openhab2_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABEUlEQVQY043Lv0sDMRjG8fu3qzipow62itKWDsVBUKqz/hkFu0jTwZrkfZO7S5rLz0vlToRO4sOHZ/sW9dNEPNzi/ah5vPOLiV9Mf7jF5C8vM/c8K9r5ZZ5f7Kfn+eYkXx/n4aDt5c5RHv0aHrgapPFpGp8Vn5UC06CPweiqroWL+3+v4JQK4AhYgShRlELouqqVVsp469oY2hhyitF7a4xrTHCuZ4NzBVmvEVFKiUIKWSKiQITupJSy7Flrd1qvlktCCAfgjCOitbb4WG8IIZRSzhj0mRCSA1LGAIByvt1+6aaxAJvXN/G+0saoulZKdTFjvCs5RwAOAmTFUJbK2JCcD01orfMxpRxT3JlkXTrYN+fRccY/ZNxVAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/930601a780f6ba270d14157ed112787e/e4706/openhab2_09.avif 230w", "/en/static/930601a780f6ba270d14157ed112787e/d1af7/openhab2_09.avif 460w", "/en/static/930601a780f6ba270d14157ed112787e/b6582/openhab2_09.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/930601a780f6ba270d14157ed112787e/a0b58/openhab2_09.webp 230w", "/en/static/930601a780f6ba270d14157ed112787e/bc10c/openhab2_09.webp 460w", "/en/static/930601a780f6ba270d14157ed112787e/87ca7/openhab2_09.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/930601a780f6ba270d14157ed112787e/81c8e/openhab2_09.png 230w", "/en/static/930601a780f6ba270d14157ed112787e/08a84/openhab2_09.png 460w", "/en/static/930601a780f6ba270d14157ed112787e/e9beb/openhab2_09.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/930601a780f6ba270d14157ed112787e/e9beb/openhab2_09.png",
              "alt": "openHAB2",
              "title": "openHAB2",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Just use your Email Address and Password + `}<strong parentName="p">{`UUID`}</strong>{` and `}<strong parentName="p">{`Secret`}</strong>{` of your OpenHAB installation - the latter can be found under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`./userdata/uuid`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`./userdata/openhabcloud/secret`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/476769eddc1e6555164953532b01f0d6/e9beb/openhab2_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB6ElEQVQoz42OTWsTURSG53cIKv0F4spFXaobtwpdSaFudSFkpQVbENGlKzcixH+gpGLTPyDRVlqaVEjT5svmY2YyN7lzzz0fd0ZmkqYipfTlWdxz3vuec7xOs03+EPu9Zs//VW8yc3ppeaOPq/hpLS6uhcX1YXFdPr9zX9/L5ofL4MmbJXp5X1bvurdL8vohvXrAhUX36IpbvuaWr16MRy/u8bNb8vQmFxbp+R0u3JYnN5KV68njhTNWFs4tPfn+hSsb/GNDKiVXKUmlJD+/yc6W7Gzxdjlnc1rKdll2yrN37nqUppiknKYK8CTSvkHf8HBi+xPQknCagkuCGEPLgeWhoaHOLD+2lKYeWZiCsYaJQjBTrIkRDBFit6v3dvXBb2g0MG9ak30gCx7lYiIDMJ5MkChjLmbTOBq02sMogsMGKoXMU4eITsPMxsRhEAAAWvtPmMxxc9TrBeNxXG/geHxOOIsbnWjF44iigNDO29BsKd+fEMJxM99MiLPpWRgRRUQpVa3WgiAUEZpLBBpHo1Y7Ugrqh6w1OYfEyPz/2YNBX2vNuUH5VHYu7nTC3b1ofz+qVlXgxyoiNcrQen52lk+cO0ueCog6J38G3RaoEELfGkOQY61HF4qZAeCgVqvX6xaRxRHzDKK/tUndw1dYxPkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/476769eddc1e6555164953532b01f0d6/e4706/openhab2_10.avif 230w", "/en/static/476769eddc1e6555164953532b01f0d6/d1af7/openhab2_10.avif 460w", "/en/static/476769eddc1e6555164953532b01f0d6/b6582/openhab2_10.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/476769eddc1e6555164953532b01f0d6/a0b58/openhab2_10.webp 230w", "/en/static/476769eddc1e6555164953532b01f0d6/bc10c/openhab2_10.webp 460w", "/en/static/476769eddc1e6555164953532b01f0d6/87ca7/openhab2_10.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/476769eddc1e6555164953532b01f0d6/81c8e/openhab2_10.png 230w", "/en/static/476769eddc1e6555164953532b01f0d6/08a84/openhab2_10.png 460w", "/en/static/476769eddc1e6555164953532b01f0d6/e9beb/openhab2_10.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/476769eddc1e6555164953532b01f0d6/e9beb/openhab2_10.png",
              "alt": "openHAB2",
              "title": "openHAB2",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c14dc8b6af9332df54680a80f263e97d/e9beb/openhab2_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABP0lEQVQoz11QQW4bMQz0x3vKSwIUveQjufTmNImRW+rEK61WWkoiqRUlBnLcoM6ciOEMhpxdLSsiMXNMmBEBABFTSiLSWitlQ2IRqbVWkd47MyMNPTPvqjQdaJQMM+s1ECmskDMycUYautaryOd21wcGmZdH55yq9n+UqhKiXwZyzs45uSRdsBvqMUic96+vf2OM/69TStZaYyxEMJP5NPcvcynlPAj6Q4rw7WxmgjWE4AnzGkJr18lyeaA58zT5hKIAHKnFomnTGehow9sc7IpHG9YzD6y4XZ3dvNk/Pr9M1sPPu3T/u6huosuajif7Ns0uwPHdYmlbU65jNczW2mkyOa24PMxm+qrq0nZOflmC95ijm+f2rTAiQqSyleBPS2b+c4AfN/H2F59sVY2ZXADnATJF5E1GZqk989ZVPwDKcAdzWAVqMwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c14dc8b6af9332df54680a80f263e97d/e4706/openhab2_11.avif 230w", "/en/static/c14dc8b6af9332df54680a80f263e97d/d1af7/openhab2_11.avif 460w", "/en/static/c14dc8b6af9332df54680a80f263e97d/b6582/openhab2_11.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c14dc8b6af9332df54680a80f263e97d/a0b58/openhab2_11.webp 230w", "/en/static/c14dc8b6af9332df54680a80f263e97d/bc10c/openhab2_11.webp 460w", "/en/static/c14dc8b6af9332df54680a80f263e97d/87ca7/openhab2_11.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c14dc8b6af9332df54680a80f263e97d/81c8e/openhab2_11.png 230w", "/en/static/c14dc8b6af9332df54680a80f263e97d/08a84/openhab2_11.png 460w", "/en/static/c14dc8b6af9332df54680a80f263e97d/e9beb/openhab2_11.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c14dc8b6af9332df54680a80f263e97d/e9beb/openhab2_11.png",
              "alt": "openHAB2",
              "title": "openHAB2",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you cannot find those files, make sure that the openHAB CLoud Connector is installed by going to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:8080/paperui`}</code>{`, choose `}<strong parentName="p">{`Addons`}</strong>{` and `}<strong parentName="p">{`Misc`}</strong>{`. Search for `}<em parentName="p">{`Cloud`}</em>{` to find the Addon and install it:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d5edc93c727a502fcb1d77f31b675d41/e9beb/openhab2_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABbklEQVQY01XKzUoCURTA8XmSVGgTgiaEldLQlxRKRFAgGZIKLQozqLTatInauLJPaJtWQg/REyhFoVkzqZXloOno3GnGe++JLKMOv8U5fw4z4XSNTTknZ2an3B7HpNNis3cPjvYM/bAM2/+y2hzmfpvezBp6+jq6epmwdzy+6b8+209Gd69O95Kx3cRJJBmNJKKRq9jebfyo5fDm/DB9cXx5sLXtmgi7HTvTIwzxG/G8nng1TVrq1VCfjvq0TbpW1xCflni1xNNG59phyQTL3bCgZ3CoDwImvNKLg1YaMMEaS/1GHGK/ut9Agla8MUBCLASMZNWC1/tJ8w2HWLrYyYhcqs6llOdHOc8pL1lFeFWeePmJl3IPiE9L2Xspx7X2jJzn5TyH+PRH7k7NpxkCIKuNt1KlUpcLQrlYei+Wq2VRwhQI/EMB6rIqIuX3ZAAAIZTJ3MkIiWK1VquhutRQVfg/lFIAEAShUHj5DgD0E4zFH6+1OgT0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d5edc93c727a502fcb1d77f31b675d41/e4706/openhab2_13.avif 230w", "/en/static/d5edc93c727a502fcb1d77f31b675d41/d1af7/openhab2_13.avif 460w", "/en/static/d5edc93c727a502fcb1d77f31b675d41/b6582/openhab2_13.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d5edc93c727a502fcb1d77f31b675d41/a0b58/openhab2_13.webp 230w", "/en/static/d5edc93c727a502fcb1d77f31b675d41/bc10c/openhab2_13.webp 460w", "/en/static/d5edc93c727a502fcb1d77f31b675d41/87ca7/openhab2_13.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d5edc93c727a502fcb1d77f31b675d41/81c8e/openhab2_13.png 230w", "/en/static/d5edc93c727a502fcb1d77f31b675d41/08a84/openhab2_13.png 460w", "/en/static/d5edc93c727a502fcb1d77f31b675d41/e9beb/openhab2_13.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d5edc93c727a502fcb1d77f31b675d41/e9beb/openhab2_13.png",
              "alt": "openHAB2",
              "title": "openHAB2",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go back to to `}<a parentName="p" {...{
        "href": "https://myopenhab.org/"
      }}>{`MyOpenHAB`}</a>{`, make sure that you are logged in, and you will see a `}<strong parentName="p">{`Online`}</strong>{` notification on the top right - if not, stop and restart the OpenHAB service from your console and refresh the webpage. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5f5b00ad88e5178af30a959711d7cf9c/e9beb/openhab2_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.478260869565215%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABAUlEQVQY042LvUrDUBiGs9c/cPVqvAZrxUk3N1uLDq21pbOLi2AiWLW1d+AlCK7ioCBIqnZSQlOTnJyecx45iZWOfvDwvN+fEwx81PANf/DOk/+BIa9Qah5fXhkJhQTGE0NqbSD6DHi+u2X4cJ/dasj+LE58UUO4VcKzKqG3j+o10TctRKfO12mZpFNn0j1CXB4irxuIqwapd0BwvM34ZAfdb6O6TVSvheq3cWRtFb21AsUClBYwa4UMinNZb21snjHr87CxBKXFv3u7M5vLONqrMEV5e6jzGaa93blllFv5xebd3NmfJZ85YSwYxSnfIkXGcU6SIOMIGUWZUw2p+R8/dOlEhOMoSQgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5f5b00ad88e5178af30a959711d7cf9c/e4706/openhab2_12.avif 230w", "/en/static/5f5b00ad88e5178af30a959711d7cf9c/d1af7/openhab2_12.avif 460w", "/en/static/5f5b00ad88e5178af30a959711d7cf9c/b6582/openhab2_12.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5f5b00ad88e5178af30a959711d7cf9c/a0b58/openhab2_12.webp 230w", "/en/static/5f5b00ad88e5178af30a959711d7cf9c/bc10c/openhab2_12.webp 460w", "/en/static/5f5b00ad88e5178af30a959711d7cf9c/87ca7/openhab2_12.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5f5b00ad88e5178af30a959711d7cf9c/81c8e/openhab2_12.png 230w", "/en/static/5f5b00ad88e5178af30a959711d7cf9c/08a84/openhab2_12.png 460w", "/en/static/5f5b00ad88e5178af30a959711d7cf9c/e9beb/openhab2_12.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5f5b00ad88e5178af30a959711d7cf9c/e9beb/openhab2_12.png",
              "alt": "openHAB2",
              "title": "openHAB2",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You will see the familiar UI when you navigate to `}<a parentName="p" {...{
        "href": "https://home.myopenhab.org/start/index"
      }}>{`https://home.myopenhab.org/start/index`}</a>{`. You can use this login on the web, on `}<a parentName="p" {...{
        "href": "https://play.google.com/store/apps/details?id=org.openhab.habdroid"
      }}>{`Android`}</a>{`, `}<a parentName="p" {...{
        "href": "https://itunes.apple.com/us/app/openhab/id492054521?mt=8"
      }}>{`iOS`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.microsoft.com/en-us/p/openhab/9nmq39ctwxgt"
      }}>{`Window Phone / Metro`}</a>{`.`}</p>
    <h2 {...{
      "id": "openhab--node-red-tutorials",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#openhab--node-red-tutorials",
        "aria-label": "openhab  node red tutorials permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB & Node-RED Tutorials`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/OpenHAB_Home_Automation/OpenHAB_Installation_on_Windows/"
        }}>{`OpenHAB Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/OpenHAB_Home_Automation/Node-RED_Installation_on_Windows/"
        }}>{`Node-RED Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/OpenHAB_Home_Automation/Mosquitto_Installation_on_Windows/"
        }}>{`MQTT Broker Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/OpenHAB_Home_Automation/IP_Camera_Control/"
        }}>{`IP Kamera Kontrolle`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/Node-RED_and_MQTT/"
        }}>{`Node-RED & OpenHAB`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      